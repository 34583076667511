.page {
}

.formWrapper {
  display: flex;
  flex: 1;
  width: 100%;

  & form {
    width: 100%;
    max-width: var(--paragraph-width);
    padding: var(--spacing-48) 0;

    display: flex;
    flex-direction: column;

    @media (--small) {
      padding-left: var(--spacing-24);
    }
  }
}

.linkWrapper {
  display: flex;
  justify-content: center;
  gap: var(--spacing-8);
  padding-top: var(--spacing-24);

  @extend %typo-body2;

  /* TODO: on some screens it is not consistent and it is centered on desktop */
  @media (--small) {
    justify-content: flex-start;
  }
}
