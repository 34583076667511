.engraving {
  margin: var(--spacing-20) 0;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--spacing-16);
}

.toggleEngravingButton {
  padding: 0;
  min-height: 1em;
  --svg-color: var(--color-dark);
}

.rotate {
  transform: rotate(45deg);
}

.engravingStatus {
  display: flex;
  justify-content: space-between;
  width: 100%;
  --svg-color: var(--color-black);

  div {
    display: flex;
    gap: var(--spacing-8);
    align-items: center;
  }

  & svg {
    width: 16px;
    height: 16px;
  }
}

.crossIcon {
  cursor: pointer;
}

.customisationModal {
  margin: var(--spacing-24);
  padding-bottom: var(--spacing-24);

  display: flex;
  flex-direction: column;
  gap: var(--spacing-8);

  form {
    padding-top: var(--spacing-16);
  }

  h3 {
    text-transform: uppercase;
    margin-bottom: var(--spacing-8);

    @extend %typo-subtitle2;
  }
}

.crossIcon {
  cursor: pointer;
}

.modalGallery .slide {
  --slide-width: 70%;
  width: var(--slide-width);

  & img {
    border-radius: 4px;
  }

  &:first-child {
    padding-left: var(--smart-page-inset);
    width: calc(
      var(--slide-width) + var(--smart-page-inset) - var(--column-gap) / 2
    );

    @media (--small) {
      padding-left: var(--page-inset);
      width: calc(var(--slide-width) + var(--page-inset));
    }
  }
  &:last-child:not(:first-child) {
    padding-right: var(--smart-page-inset);
    width: calc(var(--slide-width) + var(--smart-page-inset));

    @media (--small) {
      padding-right: var(--page-inset);
      width: calc(var(--slide-width) + var(--page-inset));
    }
  }
}
