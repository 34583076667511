.quantity-container {
  display: inline-flex;
  align-items: center;
  justify-content: space-between;

  & > button {
    border: none;
    background-color: transparent;
    color: inherit;
    font-size: inherit;
    line-height: inherit;
    cursor: pointer;
    padding: 0;
    min-height: 36px;
    min-width: 36px;
    margin: 2px;
    border-radius: 100%;

    &:hover {
      background: var(--color-tertiary);
    }
  }
  & > p {
    margin: 0 5px;
    padding: 0;
    min-width: 14px;
    text-align: center;
  }
}
