.accordion {
  &__wrapper {
    width: 100%;
    padding-bottom: 0;
  }

  &__container {
    width: 100%;
    border: none;
    padding-bottom: 0;

    > div {
      padding-bottom: 0;
    }
  }

  &__trigger {
    @extend %typo-headline2;
    font-size: 22px;
    padding: 0;

    svg {
      height: 24px;
      width: 24px;
    }
  }

  &__caption {
    font-size: 14px;
    color: var(--color-tertiary-text);
    padding-top: 1.5rem;
    margin-bottom: 0;

    @media (--small) {
      max-width: 90%;
    }
  }
}

.bolded {
  font-weight: bold;
}
.form {
  &__imageWrapper {
    width: 276px;
    height: 100%;
    width: 100%;
    height: 368px;
    position: relative;
  }

  &__productWrapper {
    display: flex;
    gap: var(--spacing-20);
    flex-wrap: wrap;

    > * {
      flex-basis: calc(50% - var(--spacing-20) / 2);
    }
  }

  &__product {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-16);
    max-width: 276px;

    img {
      border-radius: 4px;
      object-fit: cover;
      aspect-ratio: 3 / 4;
    }

    p:not(:first-child) {
      color: var(--color-secondary-text);
    }

    p:last-child {
      margin-top: var(--spacing-8);
    }
  }

  &__description {
    font-size: 14px;
    margin-top: 0.5rem;
    color: var(--color-tertiary-text);
  }
}

.giftDetailForm {
  padding-top: var(--spacing-8);
}

.giftDetailForm {
  &__buttonGroup {
    display: flex;
    gap: var(--spacing-16);
    margin-top: var(--spacing-16);

    button {
      width: 100%;
    }
  }

  &__addedMessage {
    margin-top: var(--spacing-16);
    display: flex;

    button {
      text-decoration: underline;
      background-color: transparent;
      border: none;
      cursor: pointer;
      font-size: inherit;
      font-family: inherit;
      padding: 0;
    }
  }
}

.giftDetails {
  max-width: 70%;
  @container (max-width: 400px) {
    max-width: 120px;

    & > p {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
.giftDetails > p {
  @extend %typo-body2;
  color: var(--color-text-secondary);
}
