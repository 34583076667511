.form {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.giftCards {
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
}

.hide {
  display: none;
}

.note {
    @extend %typo-body2;
    
    margin-top: 8px;
}

.accordion {
  margin-top: 40px;

  @media (--small) {
    margin-top: 48px;
  }
}