/* Typography tokens */

/*
*** Usage
** @extend %typo-headline1;
** @extend %typo-headline2;
** @extend %typo-headline3;
** @extend %typo-subtitle1;
** @extend %typo-subtitle2;
** @extend %typo-body1;
** @extend %typo-body2;
** @extend %typo-body3;
** @extend %typo-caption1;
** @extend %typo-caption2;
** @extend %typo-caption-product-card;
** @extend %typo-button;
** @extend %typo-label;
** @extend %typo-helper;
** @extend %typo-overline;
**
** Token name corresponds to figma token.
** If the typo element is marked with something like "body2/xs-xl" that means use @extend %typo-body2. 
** Breakpoints are handled through variables. Do not extend these tokens inside of breakpoints.
*/

%typo {
  &-headline {
    &1 {
      font-family: var(--font-beausite);
      font-size: var(--headline1-size);
      line-height: 1.15;
      letter-spacing: 0.02em;
      font-weight: 300;
      text-transform: uppercase;
    }

    &2 {
      font-family: var(--font-beausite);
      font-size: var(--headline2-size);
      line-height: 1.2;
      font-weight: 300;
      letter-spacing: 0.01em;
    }

    &3 {
      font-family: var(--font-beausite);
      font-size: var(--headline3-size);
      line-height: 1.2;
      font-weight: 300;
      letter-spacing: 0.01em;
    }
  }

  &-subtitle {
    &1 {
      font-family: var(--font-work-sans);
      font-size: var(--subtitle1-size);
      line-height: 1.5;
      font-weight: 400;
      letter-spacing: 0.1em;
      text-transform: uppercase;
    }

    &2 {
      font-family: var(--font-work-sans);
      font-size: var(--subtitle2-size);
      line-height: 1.5;
      font-weight: 400;
      letter-spacing: 0.1em;
    }
  }

  &-body {
    &1 {
      font-family: var(--font-beausite);
      font-size: var(--body1-size);
      line-height: 1.5;
      font-weight: 300;
      letter-spacing: 0;
    }
    &2 {
      font-family: var(--font-beausite);
      font-size: var(--body2-size);
      line-height: 1.5;
      font-weight: 300;
      letter-spacing: 0;
    }
    &3 {
      font-family: var(--font-beausite);
      font-size: var(--body3-size);
      line-height: 1.5;
      font-weight: 300;
      letter-spacing: 0.005em;
    }
  }

  &-caption {
    &1 {
      font-family: var(--font-work-sans);
      font-size: var(--caption1-size);
      line-height: 1;
      font-weight: 400;
      letter-spacing: 0.1em;
    }
    &2 {
      font-family: var(--font-work-sans);
      font-size: var(--caption2-size);
      line-height: 1.25;
      font-weight: 400;
      letter-spacing: 0.1em;
    }
    &-product-card {
      font-family: var(--font-work-sans);
      font-size: var(--caption2-size);
      line-height: 1;
      font-weight: 400;
      letter-spacing: 0.1em;
    }
  }

  &-button {
    font-family: var(--font-beausite);
    font-size: var(--font-size-s);
    font-weight: 300;
    line-height: 1;
    letter-spacing: 0.01em;
  }

  &-label {
    font-family: var(--font-work-sans);
    font-size: var(--font-size-2xs);
    font-weight: 400;
    line-height: 1;
    letter-spacing: 0.1em;
  }

  &-helper {
    font-family: var(--font-work-sans);
    font-size: var(--font-size-2xs);
    font-weight: 400;
    line-height: 1.5;
    letter-spacing: 0.01em;
  }

  &-overline {
    font-family: var(--font-work-sans);
    font-size: 13px;
    font-weight: 400;
    line-height: 2;
    letter-spacing: 0.01em;
  }

  &-underlinehover {
    position: relative;
    opacity: 1;
    transition: opacity 200ms ease;
    transition-delay: 75ms;

    &::after {
      content: "";
      position: absolute;
      bottom: -2px;
      left: 0;
      width: 100%;
      height: 1px;
      background: var(--color-text);
      transform: scaleX(0);
      transform-origin: center;
      transition: transform 200ms ease;
    }

    &:hover {
      opacity: 0.6;
      &::after {
        transform: scaleX(1);
      }
    }

    &:active {
      transform: translateY(2px);
    }

    &:disabled,
    &.disabled {
      pointer-events: none;
      &:hover {
        opacity: 1;
        &::after {
          transform: scaleX(0);
        }
      }
      &::after {
        display: none;
      }
    }
  }
}
