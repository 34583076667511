.item {
  width: 100%;
  display: flex;
  gap: var(--spacing-16);
  position: relative;
  border-bottom: 1px solid var(--color-divider);
  padding: var(--spacing-20) 0;
  container-type: inline-size;

  &:last-child {
    border: 0;
  }

  & .details__container {
    flex-grow: 1;
    display: grid;
    grid-template-columns: 2fr 1fr;
    gap: 1rem;
  }

  &__meta {
    @extend %typo-body2;
    color: var(--color-text-secondary);
  }

  &__info {
    flex: 1;
    margin-bottom: var(--spacing-32);
  }

  &__actions {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    gap: var(--spacing-16);
  }
}

.noGap {
  gap: 0;
}

.engraving {
  margin-bottom: var(--spacing-32);

  display: flex;
  flex-direction: column;
  gap: var(--spacing-16);
}

.toggleEngravingButton {
  padding: 0;
  min-height: 1em;
  --svg-color: var(--color-dark);
}

.rotate {
  transform: rotate(45deg);
  transition: transform 0.3s ease-in-out;
}

.image {
  width: 64px;
  height: 85px;
  flex-shrink: 0;
  background: var(--color-tertiary);

  & img {
    object-fit: contain;
    width: 100%;
    height: 100%;
    padding: 4px;
  }
}

.details__container_wrap {
  width: 100%;
}

.left__column {
  position: relative;
  display: flex;
  flex-direction: column;
}

.right__column {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  align-items: flex-end;
  justify-content: space-between;

  & .discounted__wrapper {
    display: flex;
    flex-direction: column-reverse;
    gap: 0.5rem;
    align-content: flex-end;

    & .discounted__price {
      opacity: 0.6;
      text-decoration: line-through;
    }
  }

  & .remove__button {
    --remove-button-padding: 0.5rem;
    position: relative;
    padding: var(--remove-button-padding);
    padding-right: 0;
    &::after {
      content: "";
      position: absolute;
      inset: 0;
      left: var(--remove-button-padding);
      top: auto;
      bottom: var(--remove-button-padding);
      width: calc(100% - var(--remove-button-padding));
      height: 1px;
      background-color: var(--color-black);
      margin-inline: auto;
      transition: all 0.15s ease;
    }
    &:hover {
      &::after {
        width: 0;
      }
    }
  }
}

.quantity__wrapper {
  border: 1px solid var(--color-divider);
  border-radius: 4px;
}

.move-button-span {
  --remove-button-padding: 0rem;
  position: relative;
  padding: var(--remove-button-padding);
  padding-right: 0;
  &::after {
    content: "";
    position: absolute;
    inset: 0;
    left: var(--remove-button-padding);
    top: auto;
    bottom: var(--remove-button-padding);
    width: calc(100% - var(--remove-button-padding));
    height: 1px;
    background-color: var(--color-black);
    margin-inline: auto;
    transition: all 0.15s ease;
  }
  &:hover {
    &::after {
      width: 0;
    }
  }
}

.move-button {
  position: absolute;
  bottom: 0;
  padding: 0;
  width: 100%;
  text-align: left;
}
