.trigger {
  padding: var(--spacing-12) 0;
  width: 100%;
  text-align: left;
  appearance: none;
  background: transparent;
  border: 0;
  --svg-color: var(--color-black);
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;

  @extend %typo-body1;

  & svg {
    width: 16px;
    height: 16px;
  }

  &[data-state="open"] {
    & svg:first-of-type {
      display: none;
    }

    & svg:last-of-type {
      display: block;
    }
  }

  &[data-state="closed"] {
    & svg:first-of-type {
      display: block;
    }

    & svg:last-of-type {
      display: none;
    }
  }
}

.accordion {
  border-bottom: 1px solid var(--color-divider);
}

.content {
  overflow: hidden;

  & > * {
    margin-bottom: 1rem;

    &:last-child {
      margin-bottom: 0;
    }
  }

  padding-bottom: var(--spacing-12);

  & > div {
    padding-block: var(--spacing-24);
  }
}

.white {
  color: var(--color-white);
  border-color: var(--color-divider-contrast);

  & svg {
    --svg-color: var(--color-white);
  }

  a {
    color: var(--color-white);
  }
}

.content[data-state="open"] {
  animation: slideDown 300ms ease-out;
}

.content[data-state="closed"] {
  animation: slideUp 300ms ease-out;
}

.decorator {
  display: flex;
  align-items: center;
  gap: var(--spacing-16);
}

.descriptor {
  color: var(--color-tertiary-text);
}

@keyframes slideDown {
  from {
    height: 0;
  }
  to {
    height: var(--radix-accordion-content-height);
  }
}

@keyframes slideUp {
  from {
    height: var(--radix-accordion-content-height);
  }
  to {
    height: 0;
  }
}
