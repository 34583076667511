.loginOptions {
  display: flex;
  justify-content: space-between;
  padding-bottom: var(--spacing-8);

  @extend %typo-body2;
}

.formWrapper {
  padding: var(--spacing-48) var(--spacing-20);

  & a {
    text-decoration: underline;
  }
}
