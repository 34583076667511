.formWrapper {
  @media (--small) {
    border-left: 1px solid var(--color-divider);
  }
}

.loginOptions {
  display: flex;
  justify-content: space-between;
  padding-bottom: var(--spacing-8);

  @extend %typo-body2;
}
