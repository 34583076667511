.button {
  --button-padding-h: 16px;
  --button-padding-v: 12px;
  --button-fs: var(--button-size);
  --button-br: 4px;
  --button-gap: 8px;
  --svg-color: var(--color-white);

  font-family: var(--font-beausite);
  font-size: var(--font-size-s);
  font-weight: 300;
  line-height: 1;
  letter-spacing: 0.01;
  display: inline-block;
  min-height: 48px;
  min-width: 80px;
  transition: all ease 150ms;
  cursor: pointer;
  align-items: center;
  font-size: var(--button-fs);
  background: var(--color-primary);
  color: var(--color-white);
  padding: calc(var(--button-padding-v) + 2px) var(--button-padding-h)
    calc(var(--button-padding-v) - 2px);
  border-radius: var(--button-br);
  appearance: none;
  border: 1px solid var(--color-primary);
  justify-content: center;
  text-decoration: none;
  line-height: 20px;
  text-align: center;
  /* display: flex; need to figure out how to handle icons better */

  @media (--small) {
    &:hover:not(:disabled) {
      background: var(--color-primary-dark);
      color: var(--color-white);
      --svg-color: var(--color-white);
    }
  }

  &.outlined:not(:hover):not(:disabled) {
    border: 1px solid var(--color-primary);
    color: var(--color-primary);
    --svg-color: var(--color-primary);
  }

  &:disabled {
    cursor: default;
    border: 1px solid var(--color-disabled-background);
    background-color: var(--color-disabled-background);
    color: var(--color-text-disabled);

  
  }

  & svg {
    width: 16px;
    height: 16px;
  }

  & path {
    transition: all ease 150ms;
  }
}

.full {
  width: 100%;
  text-align: center;

  & span {
    justify-content: center;
  }
}

.spinner {
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 3px solid var(--color-primary-light);
  border-radius: 50%;
  border-top-color: var(--color-white);
  animation: spin 1s ease-in-out infinite;
  -webkit-animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}

.black {
  background-color: var(--color-black);
  color: var(--color-white);
  border-color: var(--color-black);

  &.outlined:not(:hover) {
    border: 1px solid var(--color-black);
    color: var(--color-black);
  }
  @media (--small) {
    &:hover:not(.outlined) {
      background-color: var(--color-white);
      color: var(--color-black);
      --svg-color: var(--color-black);
    }
  }
}

.ghost {
  background-color: transparent;
  border: 0;
  color: var(--color-primary);
  --svg-color: var(--color-primary);

  @extend %typo-caption2;

  @media (--small) {
    &:hover:not(.outlined) {
      background-color: var(--color-tertiary);
      --svg-color: var(--color-primary);
    }
  }
}

.simple {
  @extend %typo-body2;
  color: var(--color-black);
  text-decoration: none;
  position: relative;
  display: inline-block;
  padding: 0 0 2px;
  background: transparent;
  border: 0;
  border-radius: 0;
  min-width: 0;
  min-height: 0;
  --svg-color: var(--color-black);
  border-bottom: 0 !important; /* Important because .button-class has border-bottom overwriting */

  @media (--small) {
    &:hover:not(:disabled) {
      --svg-color: var(--color-black);
      background: transparent;
      color: var(--color-black);
    }
  }
}

.underlined {
  &::before,
  &::after {
    width: 100%;
    content: "";
    position: absolute;
    background: var(--color-black);
    left: auto;
    display: block;
    right: 0;
    bottom: 0;
    height: 1px;
    transition: all 200ms;
    pointer-events: none;
  }

  &::after {
    width: 0%;
    left: 0;
    transition-delay: 200ms;
  }

  &:hover::after {
    width: 100%;
  }

  &:hover::before {
    width: 0%;
  }
}

.transparent {
  background-color: transparent;
  border-color: transparent;
  color: var(--color-black);
  --svg-color: var(--color-primary);
  @media (--small) {
    &:hover:not(.outlined) {
      background-color: var(--color-tertiary);
      color: var(--color-black);
      --svg-color: var(--color-primary);
    }
  }
}

.giftCard__selected.giftCard__selected {
  background-color: var(--color-tertiary) !important;
}

.giftCard__default.giftCard__default {
  background-color: transparent;
  color: var(--color-black);
  border-color: var(--color-tertiary);

  &:hover {
    color: var(--color-black);
    background-color: var(--color-tertiary);
  }
}

.caption {
  background-color: var(--color-white);
  border-color: var(--color-white);
  color: rgba(14, 14, 14, 0.64);
  --svg-color: var(--color-primary);
  text-transform: uppercase;
  min-height: 0;
  @extend %typo-caption1;
  @media (--small) {
    &:hover:not(.outlined) {
      background-color: var(--color-tertiary);
      color: rgba(14, 14, 14, 0.64);
      --svg-color: rgba(14, 14, 14, 0.64);
    }
  }
}

.outlined:not(:hover) {
  background: transparent;
  --svg-color: var(--color-black);
}

.rounded {
  border-radius: 100%;
  padding: 8px;
  aspect-ratio: 1;
  padding: 0;
  width: 48px;
  min-width: 0;
  min-height: 0;
  position: relative;

  & svg {
    position: absolute;
    inset: 0;
    margin: auto;
    width: 24px;
    height: 24px;
  }
}

.toggle {
  --svg-color: var(--color-black);

  background: transparent;
  border-color: transparent;
  color: var(--color-black);
  padding: 9px 0 7px;
  min-width: 0;
  min-height: 0;

  @media (--small) {
    &:not(:disabled):hover {
      background: transparent;
      border-color: transparent;
      color: var(--color-black);
      --svg-color: var(--color-black);
    }
  }

  & svg {
    transform: translateY(1px);
  }
}

.shadow {
  box-shadow: 0px 0px 10px rgba(215, 214, 209, 0.5);
}

.icon {
  display: flex;
  align-items: center;
}

.label {
  display: flex;
  align-items: center;

  & > span {
    display: flex;
    align-items: center;
    gap: var(--button-gap);
  }
}

.contentWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: var(--button-gap);
}
